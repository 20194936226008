<template>
  <div id="App">
    <van-steps :active="1">
      <van-step>预约日期 {{ seldate }}</van-step>
      <van-step>选择场地时间</van-step>
      <van-step>预约完成</van-step>
    </van-steps>

    <van-tabs swipeable @change="changeCD" animated v-model="cdActive">
      <van-tab
        v-for="(item, index) in cdList"
        :key="item.CODE"
        :name="index"
        :title="item.CDMC"
        style="height:80vh"
      >
        <van-field
          readonly
          clickable
          label="开始时间"
          :value="startTime"
          placeholder="选择开始时间"
          @click="
            showPicker = true;
            pickerType = 1;
          "
          style="margin-top: 15px"
        />
        <van-field
          readonly
          clickable
          label="结束时间"
          :value="endTime"
          placeholder="选择结束时间"
          @click="
            showPicker = true;
            pickerType = 2;
          "
          style="margin-top: 15px"
        />
        <van-row type="flex" justify="center" style="margin-top: 26px;">
          <van-col span="2"></van-col>
          <van-col span="20">
            <van-image width="100%" :src="tip1" v-if="tipShow" />
          </van-col>
          <van-col span="2"></van-col>
        </van-row>
      </van-tab>
    </van-tabs>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        value-key="TXT"
        :columns="pickerType == 1 ? startTimes : endTimes"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <van-submit-bar :price="hjPrice" button-text="提交订单" @submit="onSubmit">
      <van-button
        icon="arrow-left"
        type="info"
        plain
        size="small"
        @click="onClickXZRQ"
        :text="seldate"
      >
      </van-button>
    </van-submit-bar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Dialog } from "vant";
export default {
  data() {
    return {
      cdList: [],
      cdActive: 0,
      startTime: "",
      endTime: "",
      startHour: 0,
      endHour: 0,
      showPicker: false,
      pickerType: 1,
      hjPrice: 0,
      selCDCODE: "",
      startTimes: [],
      endTimes: [],
      seldate: this.$route.query.Date,
      tipShow: true
    };
  },
  computed: {
    ...mapState(["userInfo", "tip1"])
  },
  methods: {
    onConfirm(value) {
      var that = this;
      if (that.pickerType == 1) {
        that.startTime = value.SJ_TXT;
        that.startHour = value.SJ;
        that
          .$axios({
            method: "post",
            url: "/api/Main",
            data: {
              Method: "tyg050GetKYSJ",
              Params: [that.seldate, that.selCDCODE, "", value.SJ, 1]
            },
            headers: {
              Authorization: "Bearer " + that.userInfo.token
            }
          })
          .then(response => {
            if (response.data.status == "200") {
              that.endTimes = response.data.Table;
              that.endTime = response.data.Table[0].SJ_TXT;
              that.endHour = response.data.Table[0].SJ;
              that.showFY();
            } else {
              Dialog({ message: response.data.msg });
            }
          })
          .catch(error => {
            //弹出失败提醒
            Dialog({ message: error.message });
          });
      } else {
        that.endTime = value.SJ_TXT;
        that.endHour = value.SJ;
        that.showFY();
      }
      that.showPicker = false;
    },
    showFY() {
      var that = this;
      that
        .$axios({
          method: "post",
          url: "/api/Main",
          data: {
            Method: "tyg050GetYYFY",
            Params: [that.selCDCODE, that.startHour, that.endHour, that.seldate]
          },
          headers: {
            Authorization: "Bearer " + that.userInfo.token
          }
        })
        .then(response => {
          if (response.data.status == "200") {
            that.hjPrice = response.data.Table[0].FY * 100;
          } else {
            Dialog({ message: response.data.msg });
          }
        })
        .catch(error => {
          //弹出失败提醒
          Dialog({ message: error.message });
        });
    },
    onClickXZRQ() {
      this.$router.push("/addyy");
    },
    changeCD() {
      this.selCDCODE = this.cdList[this.cdActive].CODE;
      this.onLoad(this.cdList[this.cdActive].CODE);
    },
    onSubmit() {
      var that = this;
      that
        .$axios({
          method: "post",
          url: "/api/Main",
          data: {
            Method: "API_addHYYY",
            Params: [
              that.userInfo.userCardID,
              that.selCDCODE,
              that.seldate,
              that.startHour,
              that.endHour,
              that.hjPrice / 100,
              that.userInfo.wechatUserCode
            ]
          },
          headers: {
            Authorization: "Bearer " + that.userInfo.token
          }
        })
        .then(response => {
          if (response.data.status == "200") {
            if (response.data.Table[0].RET == "1") {
              this.$router.push({
                path: "/showyy",
                name: "ShowYy",
                query: {
                  code: response.data.Table[0].CODE
                }
              });
            } else {
              Dialog({ message: response.data.Table[0].TXT });
            }
          } else {
            Dialog({ message: response.data.msg });
          }
        })
        .catch(error => {
          //弹出失败提醒
          Dialog({ message: error.message });
        });
    },
    onLoad(CDCODE) {
      this.hjPrice = 0;
      this.startTime = "";
      this.endTime = "";
      this.startHour = 0;
      this.endHour = 0;
      var that = this;
      that
        .$axios({
          method: "post",
          url: "/api/Main",
          data: {
            Method: "API_getYYCD",
            Params: [that.$route.query.Date, CDCODE]
          },
          headers: {
            Authorization: "Bearer " + that.userInfo.token
          }
        })
        .then(response => {
          if (response.data.status == "200") {
            that.startTimes = response.data.Table;
            if (CDCODE == "") {
              that.cdList = response.data.Table1;
              that.selCDCODE = response.data.Table2[0].CDCODE;
            }
          } else {
            Dialog({ message: response.data.msg });
          }
        })
        .catch(error => {
          //弹出失败提醒
          Dialog({ message: error.message });
        });
    }
  },
  created: function() {
    this.onLoad("");
    var that = this;
    setTimeout(function () {
        that.tipShow = false;
      },3500);
  }
};
</script>